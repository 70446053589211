// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-categories-tsx": () => import("./../../../src/pages/categories.tsx" /* webpackChunkName: "component---src-pages-categories-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-en-categories-tsx": () => import("./../../../src/pages/en/categories.tsx" /* webpackChunkName: "component---src-pages-en-categories-tsx" */),
  "component---src-pages-en-contact-tsx": () => import("./../../../src/pages/en/contact.tsx" /* webpackChunkName: "component---src-pages-en-contact-tsx" */),
  "component---src-pages-en-privacy-policy-tsx": () => import("./../../../src/pages/en/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-en-privacy-policy-tsx" */),
  "component---src-pages-en-profile-tsx": () => import("./../../../src/pages/en/profile.tsx" /* webpackChunkName: "component---src-pages-en-profile-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-templates-all-category-posts-list-tsx": () => import("./../../../src/templates/allCategoryPostsList.tsx" /* webpackChunkName: "component---src-templates-all-category-posts-list-tsx" */),
  "component---src-templates-all-english-category-posts-list-tsx": () => import("./../../../src/templates/allEnglishCategoryPostsList.tsx" /* webpackChunkName: "component---src-templates-all-english-category-posts-list-tsx" */),
  "component---src-templates-all-english-posts-list-tsx": () => import("./../../../src/templates/allEnglishPostsList.tsx" /* webpackChunkName: "component---src-templates-all-english-posts-list-tsx" */),
  "component---src-templates-all-posts-list-tsx": () => import("./../../../src/templates/allPostsList.tsx" /* webpackChunkName: "component---src-templates-all-posts-list-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

