export const theme = {
  boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
  border: '1px #bbb solid',

  // background
  backgroundColor: 'linear-gradient(0deg, rgba(242,242,242,1) 0%, rgba(255,255,255,1) 100%)',

  // breakpoint
  breakpoint: {
    small: '730px',
    medium: '992px',
  },

  // font
  font: {
    h1: '1.5rem',
    h2: '1.6rem',
    h2_ja: '1.35rem',
    h3: '1.4rem',
    h4: '1.2rem',
    s: '0.9rem',
    xs: '0.8rem',
    sanSerif: `'Roboto', 'Hiragino Sans', 'Meiryo', sans-serif`,
    serif: `'Georgia', 'Hiragino Mincho ProN', '游明朝', serif`,
  },

  // color
  gold: 'hsl(46, 55%, 45%)',
  lightGrey: 'hsl(0, 0%, 73%)',
  grey: '#73737d',
  darkGrey: '#333',
  accentMagenta: 'hsl(300, 66%, 33%)',

  // content
  content: {
    maxWidth: '1200px',
    minSideMargin: '40px',
  },

  header: {
    height: '130px',
  },

  description: {
    verticalMargin: '50px',
  },

  footer: {
    height: '130px',
    topMargin: '20px',
  },

  siteConfig: {
    postsPerPage: 2,
  },
}
