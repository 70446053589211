import './src/styles/prism.css'

import React from 'react'
import { CSSReseter } from './src/styles/CSSreseter'
import { DefaultSettings } from './src/styles/defaultSettings'

export const rootWrapper = ({ element }) => {
  console.log(`
 .----------------. 
| .--------------. |
| |     ____     | |
| |   .' __ '.   | |
| |   | (__) |   | |
| |   .\`____'.   | |
| |  | (____) |  | |
| |  \`.______.'  | |
| |              | |
| '--------------' |
 '----------------' 
 
 WELCOME TO EIGHT BITES!!
 
 
 I would be glad if you find a bug and report me.
 
 Also, I'm always open to job offers
 
 contact me at (kame0008@gmail.com)
 
 Sincerely,
 
 Zett 
  `)
}

export const pageWrapper = ({ element }) => {
  return (
    <>
      <CSSReseter />
      <DefaultSettings />
      {element}
    </>
  )
}
